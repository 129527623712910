import { faAngleDown as lightAngleDown } from "@jewlr/pro-light-svg-icons/faAngleDown"
import { faAngleUp as lightAngleUp } from "@jewlr/pro-light-svg-icons/faAngleUp"
import { Expand } from "@jewlr/storybook/animate"
import { Box, Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import withFilters from "areas/collection/hoc/with-filters"
import FontAwesomeIcon from "components/font-awesome-icon"

import FilterCategories from "./filter-categories"

const FilterHeading = styled(Box)`
  border-bottom: ${(props) =>
    props.opened ? "none" : `1px solid ${props.theme.colors.grey[10]}`};
  ${(props) => props.theme.mediaQueries.desktop`
    border-bottom: none;
  `}
`

const FilterButton = styled(Button).attrs((props) => ({
  color: "primary",
  justifyContent: "space-between",
  padding: "8px 0",
  variant: "text",
  width: "100%",
  ...props,
}))`
  font-size: 14px;
  font-weight: bold;
`

const FilterContent = styled(Expand)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[10]};
  ${(props) => props.theme.mediaQueries.desktop`
    border-bottom: none;
  `}
`

const FilterArrow = styled(FontAwesomeIcon).attrs((props) => ({
  color: "black",
  fontSize: "18px",
  ...props,
}))``

const HeadingTitle = styled(Typography).attrs((props) => ({
  color: "black",
  fontSize: "18px",
  ...props,
}))``

// If any categories are checked, open the panel
const setDefaultOpen = (categories) => {
  return categories.some((category) => category.is_active_filter)
}

const InternalFilterPanel = ({ filter, isMobile }) => {
  const [openCategories, setOpenCategories] = useState(() =>
    setDefaultOpen(filter.categories)
  )

  return (
    <div>
      <FilterHeading opened={openCategories}>
        <Box as="h2" m="0" py={1}>
          <FilterButton
            aria-controls={`collection-filter-content-${filter.name}`}
            aria-expanded={openCategories}
            data-cy={`collection-filter-panel-${filter.name
              .replace(/\s+/g, "-")
              .toLowerCase()}-${isMobile ? "mobile" : "desktop"}`}
            id={`collection-filter-heading-${filter.name}`}
            onClick={() => setOpenCategories(!openCategories)}
          >
            <HeadingTitle>{filter.name}</HeadingTitle>
            <FilterArrow
              icon={openCategories ? lightAngleUp : lightAngleDown}
            />
          </FilterButton>
        </Box>
      </FilterHeading>
      <FilterContent
        aria-labelledby={`collection-filter-heading-${filter.name}`}
        id={`collection-filter-content-${filter.name}`}
        show={openCategories}
      >
        <FilterCategories filter={filter} isMobile={isMobile} />
      </FilterContent>
    </div>
  )
}

InternalFilterPanel.propTypes = {
  filter: PropTypes.object,
  isMobile: PropTypes.bool,
}

const FilterPanel = withFilters(InternalFilterPanel)

export default FilterPanel
