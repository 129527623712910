import { Box, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const DotIcon = styled(Box).attrs((props) => ({
  fontSize: "16px",
  ...props,
}))`
  border-radius: 50%;
  margin-right: 8px;
  ${(props) =>
    props.vermeil &&
    `
    background-color: ${props.theme.colors.grey[20]};
    border: 4px solid ${props.theme.colors.skus.ky};
  `}
`

const CategoryIcon = ({ category }) => {
  const icon = category.icon_href
  if (category.name === "Vermeil" || category.name === "Gold Plated") {
    return <DotIcon vermeil />
  } else if (icon && icon.dot_color) {
    return <DotIcon bg={`skus.${icon.dot_color}`} />
  } else if (category.belongs_to === "Stone Shape") {
    return (
      <Image
        alt={category.name}
        mr="4px"
        src={require(`images/collections/icons/stonecuts/${category.icon_tag}.svg`)}
        title={category.name}
        width="25px"
      />
    )
  } else if (
    ["Birthstone", "Stones", "Stone Type"].includes(category.belongs_to)
  ) {
    return (
      <Image
        alt={category.name}
        mr="4px"
        src={`https://assets.jewlr.com/images/product/stones/40px/1x/side/${category.icon_tag}_RD.png`}
        srcSet={
          `${`https://assets.jewlr.com/images/product/stones/40px/1x/side/${category.icon_tag}_RD.png`} 40w,` +
          ` ${`https://assets.jewlr.com/images/product/stones/40px/2x/side/${category.icon_tag}_RD.png`} 80w`
        }
        title={category.name}
        width="19px"
      />
    )
  }

  return null
}

CategoryIcon.propTypes = {
  category: PropTypes.object,
}

export default CategoryIcon
