import { Flex, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

import IntersectionWrapper from "components/common/intersection-wrapper"
import { isTestMode } from "helpers/application"
import withLanding from "hoc/landing/with-landing"

import LandingElement from "./landing-element"

const Landing = ({ landing }) => {
  useEffect(() => {
    if (!isTestMode) {
      dataLayer.push({
        event: "view-landing",
        landingPath: landing.url_path,
        landingTitle: landing.title,
        viewLandingUniqueEventId:
          window.cache_free_ssr_data.unique_event_ids.landing,
      })
    }
  }, []) // Runs only once

  return (
    <div>
      <Helmet>
        <title>{landing.title}</title>
      </Helmet>
      {landing.elements_list.map((el, index) => {
        const elId = el.anchor_name || el.id
        const landing_element = landing.elements.find((elm) => elm.id === el.id)
        let content

        if (landing_element) {
          // Only render the first THREE elements
          if (index <= 2) {
            content = (
              <LandingElement
                el={landing_element}
                index={index}
                wrapTheChild={false}
              />
            )
          } else {
            // The other elements will be load/render when being in the view port
            content = (
              <IntersectionWrapper>
                <LandingElement
                  el={landing_element}
                  index={index}
                  wrapTheChild={false}
                />
              </IntersectionWrapper>
            )
          }
        }

        return (
          <div id={elId} key={`landing-wrapper-${index}`}>
            {content}
          </div>
        )
      })}
      {landing.fetching_elements_loading && (
        <Flex style={{ justifyContent: "center", marginBottom: "50px" }}>
          <Image
            alt="loading"
            data-cy="collection-loading-icon"
            src={require("images/layout/loadinglarge.gif")}
            width="150px"
          />
        </Flex>
      )}
    </div>
  )
}

Landing.propTypes = {
  landing: PropTypes.object,
}

export default withLanding(Landing)
